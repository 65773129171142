import axios from "axios";

function getRegionalApiEndpoint(region) {
    const endpoints = {
        'prod':    `https://${region}.api.safdial.redcross.org`,
        'test':    `https://${region}.api.safdialtest.redcross.org`,
        'dev':     `https://${region}.api.dev.safdial.redcross.org`,
    };
    return endpoints[process.env.REACT_APP_STAGE]
}

export class API {

    static primaryApiEndpoint = getRegionalApiEndpoint('us-east-1');
    static backupApiEndpoint = getRegionalApiEndpoint('us-west-2');
    static usingPrimary = true;

    static async isPrimaryEndpointHealthy() {
        try {
            const response = await axios.get(`${this.primaryApiEndpoint}/health`);
            return response.data.healthy && response.status === 200;
        } catch (e) {
            return false;
        }
    }

    // If the healthcheck request succeeds and returns true, try primary.
    // If the healthcheck fails or response is false or if primary fails, use backup.
    static async createMeeting() {
        const healthy = await this.isPrimaryEndpointHealthy();

        if (healthy) {
            try {
                const response = await axios.post(`${this.primaryApiEndpoint}/dial`);
                return response.data;
            } catch (e) {
                console.warn("Call to '/dial' in primary region failed. Retrying in secondary region.");
                API.usingPrimary = false;
            }
        }

        try {
            const response = await axios.post(`${this.backupApiEndpoint}/dial`);
            return response.data;
        } catch (e) {
            console.error("Call to '/dial' in backup region failed. Cannot create meeting.");
            throw e;
        }
    }

    static async endMeeting(meetingId) {
        const endpoint = API.usingPrimary ? this.primaryApiEndpoint : this.backupApiEndpoint;
        await axios.post(`${endpoint}/update`, { update: "end", meetingId });
    }

    static async sendDigit(digit, transactionId) {
        const endpoint = API.usingPrimary ? this.primaryApiEndpoint : this.backupApiEndpoint;
        await axios.post(`${endpoint}/update`, { update: "digit", digit, transactionId });
    }

}
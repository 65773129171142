import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import GitHubIcon from "@mui/icons-material/GitHub";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./HeaderBanner";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import Main from "./Main";
// import Sidebar from "./Sidebar";
import Footer from "./Footer";
import post1 from "./blog-post.1.md";
import App from "./App";
// import post2 from "./blog-post.2.md";
// import post3 from "./blog-post.3.md";

const sections = [
  {
    title: "Disaster Relief & Recovery Services",
    url: "https://www.redcross.org/get-help/disaster-relief-and-recovery-services.html",
  },
  {
    title: "Prepare for Emergencies",
    url: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies.html",
  },
  {
    title: "Military Families",
    url: "https://www.redcross.org/about-us/our-work/military-families.html",
  },
  //   { title: "Business", url: "#" },
  //   { title: "Politics", url: "#" },
  //   { title: "Opinion", url: "#" },
  //   { title: "Science", url: "#" },
  //   { title: "Health", url: "#" },
  //   { title: "Style", url: "#" },
  //   { title: "Travel", url: "#" },
];

const mainFeaturedPost = {
  title: "SAF Hero Care Center",
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: "https://source.unsplash.com/random?wallpapers",
  imageText: "main image description",
  //linkText: "Continue reading…",
};

const featuredPosts = [
  {
    title: "Featured post",
    date: "Nov 12",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random?wallpapers",
    imageLabel: "Image Text",
  },

  {
    title: "Click here to call",
    date: "Nov 11",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random?wallpapers",
    imageLabel: "Image Text",
  },
];

const posts = [post1]; //, post2, post3];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ChimeMain() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <img
          src="/favicon.ico"
          //alt={`${APP_NAME} Logo`}
          loading="lazy"
        />
        {/* <Header title="American Red Cross" sections={sections} /> */}

        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>

          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="From the firehose" posts={posts} />
          </Grid>
        </main>
      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </ThemeProvider>
  );
}

import React, { useState, useEffect } from "react";
import {
  useMeetingManager,
  FormField,
  Input,
  Dialer,
  Phone,
  ControlBar,
  ControlBarButton,
  MeetingStatus,
  AudioInputControl,
  useMeetingStatus,
  AudioOutputControl,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { AmplifyConfig } from "./Config";
import { API } from './API';
import { Amplify, Auth } from "aws-amplify";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import Modal from "@cloudscape-design/components/modal";
import "@cloudscape-design/global-styles/index.css";
import MainFeaturedPost from "./MainFeaturedPost";
import HeaderBanner from "./HeaderBanner";
import Footer from "./Footer";
import Typography from "@mui/material/Typography";

Amplify.configure(AmplifyConfig);
Amplify.Logger.LOG_LEVEL = "DEBUG";

const App = () => {
    const meetingManager = useMeetingManager();
    const meetingStatus = useMeetingStatus();
    const [phoneNumber, setPhone] = useState("");
    const [meetingId, setMeetingId] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [visible, setVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (meetingStatus === MeetingStatus.Succeeded) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [meetingStatus]);

    const DialButtonProps = {
        icon: <Phone />,
        onClick: (event) => handleDialOut(event),
        label: "Dial",
    };

    const EndButtonProps = {
        icon: <Phone />,
        onClick: (event) => handleEnd(event),
        label: "End",
    };

    const DTMFButtonProps = {
        icon: <Dialer />,
        onClick: () => setVisible(true),
        label: "Dial Pad",
    };

    const VALID_PHONE_NUMBER =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/;

    const handlePhoneChange = (event) => setPhone(event.target.value);

    async function signOut() {
        console.log("Signing Out");
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
    }

    const handleDialOut = async (event) => {
        event.preventDefault();
        if (VALID_PHONE_NUMBER.test(phoneNumber) || phoneNumber === "") {
            try {
                const dialOutResponse = await API.createMeeting();
                console.log(dialOutResponse);
                const meetingSessionConfiguration = new MeetingSessionConfiguration(
                    dialOutResponse.responseInfo.Meeting,
                    dialOutResponse.responseInfo.Attendee
                );
                await meetingManager.join(meetingSessionConfiguration);
                await meetingManager.start();
                console.log("Meeting started");
                console.log(dialOutResponse);
                setMeetingId(dialOutResponse.responseInfo.Meeting.MeetingId);
                setTransactionId(
                    dialOutResponse.dialInfo.SipMediaApplicationCall.TransactionId
                );
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("Bad Phone Number");
        }
    };

    const handleEnd = async (event) => {
        event.preventDefault();
        await API.endMeeting(meetingId).then(console.log).catch(console.error);
    };

    const handleSendDigit = async (digit) => {
        // event.preventDefault();
        await API.sendDigit(digit, transactionId).then(console.log).catch(console.error);
    };

    const Logo = () => (
        <img
            src="/favicon.ico"
            loading="lazy"
        />
    );

    const mainFeaturedPost = {
        title: "Service to the Armed Forces",
        description: "Hero Care Center",
        image: "./saf-hero-center.jpg",
        imageText: "Hero Care Center",
        //linkText: "Continue reading…",
    };

    const sections = [
        {
            title: "Disaster Relief & Recovery Services",
            url: "https://www.redcross.org/get-help/disaster-relief-and-recovery-services.html",
        },
        {
            title: "Prepare for Emergencies",
            url: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies.html",
        },
        {
            title: "Military Families",
            url: "https://www.redcross.org/about-us/our-work/military-families.html",
        },
    ];

    const divStyle = {
        width: "1200px",
        // height: "1200px",
        justifyContent: "center",
        margin: "0 auto",
    };

    const controlBarStyle = {
        color: "black",
        // backgroundColor: "DodgerBlue",
        padding: "20px",
        fontFamily: "Arial",
        top: "unset",
        bottom: "auto",
        // position: "relative"
    };

    return (
        <>
            <div className="App" style={divStyle}>
                {/* <div className="w-full"> */}
                <div>
                    <img
                        src="/arc-brand-logo.png"
                        //alt={`${APP_NAME} Logo`}
                        loading="lazy"
                    />

                    <HeaderBanner title="American Red Cross" sections={sections} />
                    <MainFeaturedPost post={mainFeaturedPost} />

                    <header className="flex bg-gray-900 m-5 p-5 shadow-lg rounded-lg">
                        <Typography variant="h5" color="text.primary" sx={{ paddingY: 1 }}>
                            <b>Steps before placing calls:</b>
                            <ul>
                                <li>
                                    <b>Computer (laptop, desktop): </b>When prompted click allow
                                    on the pop up box for microphone access.
                                </li>
                                <li>
                                    <b>Smart phone users:</b> Ensure microphone access is allowed
                                    when using the browser/internet. Adjusting these settings
                                    varies by manufacturer.
                                </li>
                                <li>
                                    <b>Ending Call: </b> To end the call click 'Dial/End' button.
                                </li>
                            </ul>
                        </Typography>
                    </header>
                    <Container
                        header={
                            <Header variant="h2" description="">
                                Click "Dial" to Call
                            </Header>
                        }
                    >
                        <label>
                            {" "}
                            Clicking 'Dial' will connect you with the Hero Care Center where
                            you will be able to speak with a caseworker to initiate your
                            request for a Red Cross Emergency Communication message. Your call
                            is placed via the internet at no additional cost to you.
                        </label>
                        {/* <div css="width: 200px; padding: 20px; position: relative"> */}
                        <div style={controlBarStyle}>
                            <ControlBar
                                showLabels={true}
                                responsive={true}
                                layout="undocked-horizontal"
                                css="position:relative; width: 10%"
                                // css="margin: 10px;  background-color: rgba(0,0,0,.0); box-shadow: 0px 0px;"
                            >
                                {isActive ? (
                                    <ControlBarButton {...EndButtonProps} />
                                ) : (
                                    <ControlBarButton {...DialButtonProps} />
                                )}
                            </ControlBar>
                            <label>
                                {" "}
                                Clicking on the 'Dial' Button, you will be connected to SAF Hero
                                Care Center.
                                <br /> In case if the page pops-up requesting for Mic/Headphones
                                permission, click allow button to get connected to call center.
                            </label>
                            <br />
                            <ControlBar
                                showLabels={true}
                                responsive={true}
                                layout="undocked-horizontal"
                                css="position:relative; width: 7%; margin:15px"
                            >
                                <ControlBarButton {...DTMFButtonProps} />

                                {/* <AudioInputControl />
                <AudioOutputControl /> */}
                                <Modal
                                    onDismiss={() => setVisible(false)}
                                    size={"small"}
                                    visible={visible}
                                    closeAriaLabel="Close modal"
                                    footer={
                                        <Box float="right">
                                            <Grid
                                                gridDefinition={[
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                    { colspan: 4 },
                                                ]}
                                            >
                                                <div>
                                                    <Button
                                                        label="1"
                                                        onClick={() => handleSendDigit("1")}
                                                    >
                                                        1
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="2"
                                                        onClick={() => handleSendDigit("2")}
                                                    >
                                                        2
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="3"
                                                        onClick={() => handleSendDigit("3")}
                                                    >
                                                        3
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="4"
                                                        onClick={() => handleSendDigit("4")}
                                                    >
                                                        4
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="5"
                                                        onClick={() => handleSendDigit("5")}
                                                    >
                                                        5
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="6"
                                                        onClick={() => handleSendDigit("6")}
                                                    >
                                                        6
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="7"
                                                        onClick={() => handleSendDigit("7")}
                                                    >
                                                        7
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="8"
                                                        onClick={() => handleSendDigit("8")}
                                                    >
                                                        8
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="9"
                                                        onClick={() => handleSendDigit("9")}
                                                    >
                                                        9
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="*"
                                                        onClick={() => handleSendDigit("*")}
                                                    >
                                                        *
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="0"
                                                        onClick={() => handleSendDigit("0")}
                                                    >
                                                        0
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        label="#"
                                                        onClick={() => handleSendDigit("#")}
                                                    >
                                                        #
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Box>
                                    }
                                    header="Send Digits"
                                ></Modal>
                            </ControlBar>
                            <label>
                                Dial Pad - On clicking Dial Pad, a popup window is displayed
                                with the numbers. You may use this to navigate in the IVR
                                response to Phone call.
                            </label>
                        </div>
                    </Container>
                    {/* </ContentLayout> */}

                    <br />

                    <Footer
                        title="SAF Hero Care Center"
                    />
                </div>
            </div>
        </>
    );
};

export default App;
